function phoneNumberMask() {
	const inputs = document.querySelectorAll('[type="tel"]');

	const createMask = (value) => {
		value = value.replace(/\D/g, ''); // Removes everything that is not a digit.
		value = value.replace(/^(\d{2})(\d)/g, '($1) $2'); // Place parentheses around the first two digits.
		value = value.replace(/(\d)(\d{4})$/, '$1-$2'); // Put a hyphen between the fourth and fifth digits.
		return value;
	};

	inputs.forEach((input) => {
		input.setAttribute('minlength', '14');
		input.setAttribute('maxlength', '15');

		input.addEventListener('keyup', () => {
			setTimeout(() => {
				input.value = createMask(input.value);
			}, 1);
		});
	});
}

document.addEventListener('DOMContentLoaded', phoneNumberMask);
